<template>
  <div class="coronavirus" v-if="this.showBanner">
    <BIconX class="closeable" @click="showBanner=false">
    </BIconX>
    <div class="container text-center">
       <span class="text">
          <h4 class="title">Travel restrictions update</h4>
          American CDC cards are accepted for entry into museums, attractions, restaurants, and cafes in all our European destinations and tours.
        </span>
    </div>
  </div>
</template>

<script>
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'NotificationBanner',
  components: {
    BIconX
  },
  data () {
    return {
      currentPath: this.$router.currentRoute.fullPath,
      illegalRoutes: [
        '/payment',
        '/payment/',
        '/confirm',
        '/confirm/',
      ],
      showBanner: true
    }
  },
  watch: {
    '$route': function (to, from) {
      this.checkLocation()
    }
  },
  mounted () {
    this.checkLocation()
  },
  methods: {
    checkLocation () {
      if (!this.showBanner) {
        return
      }
      this.currentPath = this.$router.currentRoute.fullPath
      if (this.illegalRoutes.includes(this.currentPath)) {
        this.showBanner = false
      } else {
        this.showBanner = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .coronavirus {
    position: relative;
    background: #DFDFDF;
    padding: 1em 0;
      a {
        color: black;
      }
      .text {
        text-decoration: none;
        font-size: 14px;
        font-family: "Alegreya Sans",sans-serif!important;
        .title {
          margin-top: 0;
          margin-bottom: 1em;
          font-size: 14px;
          font-weight: bold;
        }
      }
  }
  .closeable{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 2em !important;
    height: 2em !important;
  }
</style>