<template>
</template>

<script>
export default {
  name: 'ChatWidget',
  data () {
    return {
      zendeskLoaded: false
    }
  },
  watch: {
    $route(to,from){
      this.handleRoute(to)
    }
  },
  mounted() {
    setTimeout(() => this.handleRoute(this.$route), 2000)
  },
  methods: {
    handleRoute (route) {
      if(route.path && (
        route.path === '/' || 
        route.path.endsWith('-tours') || 
        route.path.endsWith('-tours/') || 
        route.path.indexOf('/payment') != -1 || 
        route.path.indexOf('/contact') != -1
      )){
        if(this.zendeskLoaded){
          $('.embeddedServiceHelpButton').css('display','block')
        }else{
          this.loadChat()
        }
      }else{
        $('.embeddedServiceHelpButton').css('display','none')
      }
    },
    loadChat () {
      if (this.zendeskLoaded === false) {
        this.zendeskLoaded = true
        let script = document.createElement("script")
        script.type = "text/javascript"
        script.src = 'https://hornblower.secure.force.com/resource/ChatButtonCityCruises'
        script.id = 'ze-snippet'
        script.onload = () => {}
        document.getElementsByTagName("head")[0].appendChild(script)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/sass/_variables.scss';

.embeddedServiceHelpButton .helpButton {
  height: unset !important;
}

  .embeddedServiceHelpButton .helpButton .uiButton{
    background-color: $label-grey !important;
    font-family: "Arial", sans-serif;
    height: 30px !important;
    min-width: 5em !important;
    .helpButtonLabel{
      max-width: 80%;
    }
  }
  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid $label-grey !important;
  }
  .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer,
  .embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer {
    margin-bottom: 50px;
  }
  h2[embeddedService-chatHeader_chatHeader] {
    color: #ffffff !important;
  }
  .embeddedServiceHelpButton.hb_repositioned_chat_btn {
    z-index: 1111 !important;
  }
  .embeddedServiceHelpButton.hb_repositioned_chat_btn .helpButton {
    top: 74px;
    right: 10px !important;
  }
  .embeddedServiceHelpButton.hb_repositioned_chat_btn .helpButton .uiButton {
    border-radius: 8px !important;
    min-width: auto;
  }
    .embeddedServiceHelpButton .uiButton .helpButtonLabel .message{
    font-family: Alegreya Sans;
  }

// form label
.embeddedServiceSidebarForm label > span.required,
.embeddedServiceSidebarForm label > span.required:not(.choices):not(select):not(.wfAutosuggest) {
  color: red !important;
  border: none !important;
  padding: 0 !important;
  width: 0;
}
// form header
.embeddedServiceSidebar > div > embeddedservice-chat-header {
  background-color: $woi-red-main;
}
// form button
.embeddedServiceSidebar .buttonWrapper.embeddedServiceSidebarForm > button {
  background-color: $woi-red-main;
}

@include max-width($mobile) {
  embeddedservice-chat-header > header > button.closeButton,
  embeddedservice-chat-header > header > button.minimizeButton {
    padding-left: 6px;
    padding-right: 6px;
  }
  svg.slds-icon.slds-icon-text-default.slds-icon_x-small {
    height: 20px;
    width: 20px;
  }
}
</style>