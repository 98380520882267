<template>
  <LazyBackground
    class="container-fluid image banner"
    :imageSource="this.setAutoWidth(imageurl)"
    :loadingImage="this.setAutoWidth(imageurl)">  
      <div class="banner-overlay"></div>
      <div class="container">
          <h1>{{ label }}</h1>
          <h2>{{ subTitle }}</h2>
      </div>
  </LazyBackground>
</template>

<script>
import LazyBackground from '~/components/utils/LazyBackground'

import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  components: {
    LazyBackground
  },
  mixins: [imageOptimo],
  name: 'Banner',
  props: {
    imageurl: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
  .banner .container{
    display: block;
  }

  .banner .container h2{
    color: #FFF;
  }
</style>
