<template>
  <div id="cart-dd" class="sidebar shopping-cart hidden">
    <div id="header-cart-heading" class="sidebar-heading">
      <h2 id="header-cart-heading-title" class="heading">Cart</h2>
      <div class="close-cart" @click="closeCart()">
        <img id="header-cart-close" class="icon icon-collapse lazyload" data-src="~/assets/svg/close.svg" width="24" height="24">
      </div>
    </div>
    <div id="header-cart-checkout" class="right-sidebar-item">
      <div id="header-cart-cta" class="sidebar-content cta">
        <h5 id="header-cart-subtitle" class="subtitle grey">{{ getSubTotalTitle() }}</h5>
        <h2 id="header-cart-subtotal" class="subtotal-price default">{{ getTotalPrice() }}</h2>
        <router-link v-if="this.anchorCarts > 0" :to="{path: '/payment/'}" exact class="btn primary purple">
          Checkout
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CartDropdown',
  computed: {
    ...mapGetters([
      'anchorCarts',
      'currency',
      'headerCartVisible'
    ])
  },
  watch: {
    headerCartVisible (to, from) {
      const _this = this
      if(to){
        $(document).click((e)=>{
          if(
            e.target.id !== 'mobile-header-cart-icon' &&
            e.target.id !== 'header-cart-icon' &&
            e.target.id !== 'header-cart-heading' &&
            e.target.id !== 'header-cart-items' &&
            e.target.id !== 'header-cart-checkout' &&
            e.target.id !== 'header-cart-subtitle' &&
            e.target.id !== 'header-cart-subtotal' &&
            e.target.id !== 'header-cart-cta' &&
            e.target.id !== 'header-cart-heading-title'
          ){
            if (e.target.tagName === 'path') {
              return
            }
            _this.closeCart()
          }
        })
      }else{
        $(document).off('click')
      }
    }
  },
  methods: {
    getSubTotalTitle () {
      if (this.anchorCarts > 0) {
        return 'Subtotal'
      } else {
        return ' '
      }
    },
    getTotalPrice () {
      

      if (this.anchorCarts > 0) {
        var total = 0
        // for (var ind in this.carts) {
        //   var item = this.carts[ind]
        //   var subTotal = 0
        //   for (var index in item.guests) {
        //     var guest = item.guests[index]
        //     subTotal += this.convertPrice(item, guest)
        //   }
        //   total += subTotal
        // }
        return this.convertFormatPrice(total)
      } else {
        return 'EMPTY'
      }
    },
    convertPrice (item, guest) {
      var price = 0
      if (guest.type === 'adults') {
        price = item.price.adults_price
      } else if (guest.type === 'infants') {
        price = item.price.infants_price
      } else if (guest.type === 'children') {
        price = item.price.children_price
      } else {
        price = item.price.students_price
      }

      switch (this.currency) {
        case 'USD':
          return price.USD * guest.count
        case 'EUR':
          return price.EUR * guest.count
        case 'GBP':
          return price.GBP * guest.count
        case 'CAD':
          return price.CAD * guest.count
        case 'AUD':
          return price.AUD * guest.count
      }
      return price
    },
    convertFormatPrice (price) {
      if (price === 0) {
        return ''
      }

      switch (this.currency) {
        case 'USD':
          return '$' + price.toFixed(2)
        case 'EUR':
          return '€' + price.toFixed(2)
        case 'GBP':
          return '£' + price.toFixed(2)
        case 'CAD':
          return '$' + price.toFixed(2)
        case 'AUD':
          return '$' + price.toFixed(2)
      }
      return price
    },
    closeCart () {
      const cart = document.getElementById('cart-dd')
      const bg = document.getElementById('bg-nav')
      cart.classList.remove('active')
      bg.classList.remove('show')
      bg.classList.remove('open')
      cart.style.display = 'none'
      this.$store.commit('setHeaderCartVisible', false)
    },
  }
}
</script>
