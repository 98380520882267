// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".embeddedServiceHelpButton .helpButton{height:unset!important}.embeddedServiceHelpButton .helpButton .uiButton{background-color:#67626b!important;font-family:\"Arial\",sans-serif;height:30px!important;min-width:5em!important}.embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel{max-width:80%}.embeddedServiceHelpButton .helpButton .uiButton:focus{outline:1px solid #67626b!important}.embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer,.embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer{margin-bottom:50px}h2[embeddedService-chatHeader_chatHeader]{color:#fff!important}.embeddedServiceHelpButton.hb_repositioned_chat_btn{z-index:1111!important}.embeddedServiceHelpButton.hb_repositioned_chat_btn .helpButton{top:74px;right:10px!important}.embeddedServiceHelpButton.hb_repositioned_chat_btn .helpButton .uiButton{border-radius:8px!important;min-width:auto}.embeddedServiceHelpButton .uiButton .helpButtonLabel .message{font-family:Alegreya Sans}.embeddedServiceSidebarForm label>span.required,.embeddedServiceSidebarForm label>span.required:not(.choices):not(select):not(.wfAutosuggest){color:red!important;border:none!important;padding:0!important;width:0}.embeddedServiceSidebar .buttonWrapper.embeddedServiceSidebarForm>button,.embeddedServiceSidebar>div>embeddedservice-chat-header{background-color:#5e2013}@media screen and (max-width:640px){embeddedservice-chat-header>header>button.closeButton,embeddedservice-chat-header>header>button.minimizeButton{padding-left:6px;padding-right:6px}svg.slds-icon.slds-icon-text-default.slds-icon_x-small{height:20px;width:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandRed": "#5e2013",
	"brandGold": "#a08f56",
	"tableWidth": "1024px",
	"mobileExtra": "700px",
	"mobile": "640px",
	"mobileLarge": "480px",
	"blogCardWidthMobile": "200px",
	"locationCardWidth": "412px",
	"locationCardWidthTab": "300px",
	"locationCardWidthMobile": "242px"
};
module.exports = ___CSS_LOADER_EXPORT___;
