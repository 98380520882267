
const getRouteName = (routeName) => {
  switch (routeName) {
    case 'index': return 'homepage'
    case 'city': return 'CityPage'
    case 'city-tour': return 'TourPage'
    case 'about': return 'AboutPage'
    case 'contact': return 'ContactPage'
    case 'travel-agents': return 'TravelAgentPage'
    case 'offers-offer': return 'PromoPage'
    case 'faq': return 'faq'
    case 'terms':
    case 'privacy':
    case 'cancellation':
    case 'terms-of-use': return 'policypage'
    default: return routeName
  }
}

export const includesPage = (pagesToShow, pageName) => {
  if (!pagesToShow || !pageName) {
    return false
  }
  if (pagesToShow.includes('All pages on site') || pagesToShow.includes('Whole Site')) {
    return true
  }
  const match = getRouteName(pageName).toLowerCase()
  if (pagesToShow.includes('Whole Site (except Homepage)')) {
    return match !== 'homepage'
  }
  const processedPageNames = pagesToShow.map((pageName) => pageName.replaceAll(' ', '').toLowerCase())
  const res = processedPageNames.includes(match) || processedPageNames.includes(`${match}s`)
  // console.log('processedPageNames => ', processedPageNames)
  // console.log('processed pageName => ', match)
  // console.log('can show => ', res)
  return res
}

export const getTrailingSlash = (path) => {
  let trailingSlash = ''
      if (!path.match(/\/$/)) {
        trailingSlash = '/'
      }
  return trailingSlash
}

export const isFilterMatched = (filter, queryFilter) => {
  if (!filter || !queryFilter) {
    return false
  }
  const processedFilter = filter.replace(/\s/g, "-").replace(/\./g, '')
  .replace(/&/g, '').replace(/'/g, '').replace(/--/g, '-')?.toLowerCase()
  return processedFilter === queryFilter.toLowerCase()
}

/**
 * remove the last / of path if exist
 * and path not equal to '/'
 * @param {String} path url to be trimmed
 * @return {String}
 */
export const removeTrailingSlash = (path) => {
  const end = path.length - 1
  const lastChar = path.charAt(end)
  return lastChar === '/' && path.length > 1 ? path.slice(0, end) : path
}


/**
 * Captialize String
 * e.g 'rome tour' => 'Rome Tour'
 * @param {String} text
 * @return {String}
 */
export const capitalize = (text) => {
  const arr = text.split(' ')
  return arr.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }).join(' ').trim()
}