<template>
  <div class="footer">
    <Subscriber class="newsletter" />
    <div class="footer-wrapper">
      <div class="footer-links-wrapper">
        <LeftSection class="left-section-imgs" />
        <div class="right-footer-links">
          <FooterLinkColumn
            v-for="({ links, title }, index) in footerLinks"
            :key="index"
            :links="links"
            :title="title"
            :mobile="screenWidth <= 1024"
          />
        </div>
        
      </div>
      <div class="footer-copyright">
        <div class="copyright">
          {{
            'Copyright ©  ' + new Date().getFullYear() + ' Walks of Italy ™ , Viale Giuseppe Mazzini 88, Rome, Italy 00195 '
          }}
        </div>
        
      </div>
    </div>
    <ChatWidget/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterLinks from '@/components/utils/FooterLinks'
import Subscriber from '@/components/utils/Subscriber'
import RightNote from '@/components/utils/RightNote'
import ChatWidget from '~/components/utils/ChatWidget'
import LeftSection from '@/components/containers/footer/LeftSection'
import FooterLinkColumn from '@/components/containers/footer/FooterLinkColumn'

export default {
  name: 'Footer',
  components: {
    FooterLinks,
    Subscriber,
    RightNote,
    ChatWidget,
    LeftSection,
    FooterLinkColumn
  },
  computed: {
    ...mapGetters([
      'screenWidth'
    ]),
  },
  data () {
    return {
      // FooterLogo: FooterLogo
      footerLinks: [
        {
          title: 'Company',
          links: [
            {
              name: 'About Us',
              router: '/about/'
            },
            // {
            //   name: `Careers & We’re Hiring Guides`,
            //   router: '/careers/'
            // },
            {
              name: 'Contact Us',
              router: '/contact/'
            }
          ]
        },
        {
          title: 'Support',
          links: [
            {
              name: 'Help Center',
              router: '/faq/'
            },
            {
              name: 'Terms & Conditions',
              router: '/terms/'
            },
            {
              name: 'Cancellation Policy',
              router: '/cancellation/'
            },
            {
              name: 'Health & Safety',
              router: '/health-safety/'
            },
            {
              name: 'Privacy Policy',
              router: '/privacy/'
            },
            {
              name: 'Cookies Settings',
              router: '/'
            }
          ]
        },
        {
          title: 'Partners',
          links: [
            // {
            //   name: 'Affiliate Program',
            //   router: '/walks-travel-affiliate-program/'
            // },
            {
              name: 'Travel Agents',
              router: '/travel-agents/'
            }
          ]
        },
        {
          title: 'Links',
          links: [
            {
              name: 'Blog',
              router: 'https://www.walksofitaly.com/blog/',
              target: '_blank'
            }
            // ,
            // {
            //   name: 'Giftcard / Vouchers',
            //   router: '/gift-cards/'
            // }
          ]
        }
      ]
    }
  },
  methods: {
    returnCurrenyYear() {
      let year = new Date().getFullYear();
      return year;
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  // position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: $woi-red-main;
  padding-bottom: 8px;
  @include subtitle-2;
  color: $white;
  .newsletter {
    width: 100%;
    padding: 36px 20px;
    border-bottom: 4px solid $white;
  }
  .footer-wrapper {
    width: $content-width-ex;
  }
  .footer-links-wrapper {
    padding: 12px 0 8px 0;
    // border-top: 4px solid $white;
    display: flex;
    // gap: 68px;
    justify-content: space-between;
    .right-footer-links {
      display: flex;
      min-width: calc(100% - 300px);
    }
  }

  .footer-copyright {
    background-color: $woi-red-main;
    text-align: left;
    font-size: 12px;
    line-height: 22px;
    // .address {
    //   padding: 8px 0;
    //   border-bottom: 1px solid $white;
    //   font-weight: 700;
    // }
    .copyright {
      font-weight: 700;
      padding: 8px 0;
    }
  }
}
@include max-width(1340px) {
  .footer .footer-wrapper {
    width: $content-width;
  }
}
@include max-width($tablet) {
  .footer {
    justify-content: center;
    padding-bottom: 48px;
    .newsletter {
      // width: 100%;
      // padding: 36px 0;
      border-bottom: 2px solid $white;
    }
    // .footer-wrapper {
    //   width: calc(100vw - 48px);
    // }
    .footer-links-wrapper {
      padding: 12px 0 8px 0;
      border-top: none;
      // display: flex;
      flex-direction: column-reverse;
      gap: unset;
      align-items: center;
      .right-footer-links {
        width: 100%;
        flex-direction: column;
      }
    }

    .footer-copyright {
      text-align: center;
    }
  }
}
// @include max-width($mobile) {
//   .footer .footer-wrapper {
//     width: calc(100vw - 36px);
//   }
// }

</style>