<template>
  <div class="footer-links">
    <div class="large-screen">
  
      <!-- tours section -->
      <ul class="large">
        <li>
          <nuxt-link to="#">
            tours
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/rome-tours/">
            Rome
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/vatican-tours/">
            vatican
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/florence-tours/">
            florence
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/venice-tours/">
            venice
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/milan-tours/">
            milan
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/pompeii-tours/">
            pompeii / amalfi
          </nuxt-link>
        </li>
        <li>
          <a href="https://www.takewalks.com/" target="_blank">
            Other Destinations
          </a>
        </li>
      </ul>
      <!-- links section -->
      <ul class="large">
        <li>
          <nuxt-link to="#">
            Links
          </nuxt-link>
        </li>
        <li>
          <a target="_blank" href="https://www.walksofitaly.com/blog/">
            blog
          </a>
        </li>
        <li>
          <nuxt-link to="/about/">
            about
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/faq/">
            faq
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/contact/">
            contact
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/travel-agents/">
            travel agents
          </nuxt-link>
        </li>
      </ul>
      <!-- policies section -->
      <ul class="large">
        <li>
          policies
        </li>
        <li>
          <nuxt-link to="/terms/">
            terms & conditions
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/cancellation/">
            cancellation policy
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/privacy/">
            privacy policy
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/terms-of-use/">
            Terms of Use
          </nuxt-link>
        </li>
        <li>
          <!-- OneTrust Cookies Settings button start -->
          <a id="ot-sdk-btn" class="ot-sdk-show-settings">
            Cookie Settings
          </a>
          <!-- OneTrust Cookies Settings button end -->
        </li>
      </ul>
    </div>
    
    <div class="small">
      <!-- tours -->
      <AccordionItem
        label="tours">
        <ul>
          <nuxt-link to="/rome-tours/">
            <li>
              Rome
            </li>
          </nuxt-link>
          <nuxt-link to="/vatican-tours/">
            <li>
              vatican
            </li>
          </nuxt-link>
          <nuxt-link to="/florence-tours/">
            <li>
              florence
            </li>
          </nuxt-link>
          <nuxt-link to="/venice-tours/">
            <li>
              venice
            </li>
          </nuxt-link>
          <nuxt-link to="/milan-tours/">
            <li>
              milan
            </li>
          </nuxt-link>
          <nuxt-link to="/pompeii-tours/">
            <li>
              pompeii / amalfi
            </li>
          </nuxt-link>
            <li>
              <a href="https://www.takewalks.com/" target="_blank">
                Other Destinations
              </a>
            </li>
        </ul>
      </AccordionItem>

      <!-- links -->
      <AccordionItem
        label="links" >
        <ul>
          <a target="_blank" href="https://www.walksofitaly.com/blog/">
            <li>
              blog
            </li>
          </a>
          <nuxt-link to="/about/">
            <li>
              about
            </li>
          </nuxt-link>
          <nuxt-link to="/faq/">
            <li>
              faq
            </li>
          </nuxt-link>
          <nuxt-link to="/contact/">
            <li>
              contact
            </li>
          </nuxt-link>
          <nuxt-link to="/travel-agents/">
            <li>
              travel agents
            </li>
          </nuxt-link>
        </ul>
      </AccordionItem>


      <!-- policies  -->
      <AccordionItem
        label="policies" >
        <ul>
          <nuxt-link to="/terms/">
            <li>
              terms & conditions
            </li>
          </nuxt-link>
          <nuxt-link to="/cancellation/">
            <li>
              cancellation policy
            </li>
          </nuxt-link>
          <nuxt-link to="/privacy/">
            <li>
              privacy policy
            </li>
          </nuxt-link>
          <nuxt-link to="/terms-of-use/">
            <li>
              terms of use
            </li>
          </nuxt-link>
          <li>
            <!-- OneTrust Cookies Settings button start -->
            <a id="ot-sdk-btn" class="ot-sdk-show-settings">
              Cookie Settings
            </a>
            <!-- OneTrust Cookies Settings button end -->
          </li>
        </ul>
      </AccordionItem>
    </div>
  </div>
</template>

<script>
import AccordionItem from '@/components/utils/AccordionItem'
export default {
  name: 'FooterLink',
  components: {
    AccordionItem
  }
}
</script>

