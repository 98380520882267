<template>
<div class="mobile-menu-hamburger">
  <div class="menu-wrapper">
    <div class="menu-header">
      <svgicon name="close-circle" class="icon-close" height="24" width="24" @click="close" />
      <nuxt-link to="/" @click.native="clickHome();close()">
        <img class="walks-logo" :src="require('~/assets/svg/logo.svg')" alt="Walks Of Italy logo">
      </nuxt-link>
    </div>
    <div class="menu-content-wrapper">
      <AccordionItem class="cities-menu" label="Tours in Italy" :isClosed="false" :color="'red'" :text-color="'#5E2013'">
        <div class="sub-container">
          <template v-for="(item, index) in cities">
            <div :key="'city' + index" class="sub-item-wrapper">
              <nuxt-link :key="'city-link' + index" class="sub-item" :to="item.router" @click.native="clickMegaMenu(item.name, item.router);close()">
                <img :data-src="getThumbnail(item.imgurl)" class="city-img lazyload" width="100%" height="auto"/>
                <span class="city-name">{{item.name}}</span>
                <svgicon class="arrow-icon" name="arrow-round" />
              </nuxt-link>
            </div>
          </template>
        </div>
      </AccordionItem>
      <AccordionItem class="cities-menu" label="Tours in France" :isClosed="false" :color="'red'" :text-color="'#5E2013'">
        <div class="sub-container">
          <template v-for="(item, index) in franceCities">
            <div :key="'france-city' + index" class="sub-item-wrapper">
              <nuxt-link :key="'france-city-link' + index" class="sub-item" :to="item.router" @click.native="clickMegaMenu(item.name, item.router);close()">
                <img :data-src="getThumbnail(item.imgurl)" class="city-img lazyload" width="100%" height="auto"/>
                <span class="city-name">{{item.name}}</span>
                <svgicon class="arrow-icon" name="arrow-round" />
              </nuxt-link>
            </div>
          </template>
        </div>
      </AccordionItem>
      <AccordionItem class="cities-menu" label="Tours across Europe" :isClosed="false" :color="'red'" :text-color="'#5E2013'">
        <a href="https://www.takewalks.com/" 
          class="underscore" 
          @click="clickMegaMenuTW('takewalks', 'https://www.takewalks.com/')">
          Available to book on our worldwide website
        </a>
        <div class="sub-container">
          <template v-for="(item, index) in otherDestination">
            <div :key="'other-city' + index" class="sub-item-wrapper">
              <a :key="'other-city-link' + index" class="sub-item" :href="item.router" @click="clickMegaMenu(item.name, item.router);close()">
                <img :data-src="getThumbnail(item.imgurl)" class="city-img lazyload" width="100%" height="auto"/>
                <span class="city-name">{{item.name}}</span>
                <svgicon class="arrow-icon" name="arrow-round" />
              </a>
            </div>
          </template>
        </div>
      </AccordionItem>
      <nuxt-link class="menu-item" v-if="offerLink" :to="offerLink" @click.native="clickMegaMenu('Offers', offerLink);close()">
        <span class='text'>Offers</span>
      </nuxt-link>
      <nuxt-link class="menu-item" v-if="displayMyBooking" :to="'/booking-manage/'" @click.native="clickMegaMenu('my booking', '/booking-manage/');close()">
        <span class='text'>my booking</span>
      </nuxt-link>
      <template v-for="(item, index) in menu">
        <template v-if="item.name !== 'blog'">
          <nuxt-link class="menu-item" :key="'menu' + index" :to="item.router" @click.native="clickMegaMenu(item.name, item.router);close()">
            <span class="text">{{item.name}}</span>
          </nuxt-link>
        </template>
        <template v-else>
          <a class="menu-item" :key="'menu' + index" :href="item.router" target="_blank" @click="clickMegaMenuTW(item.name, item.router);close()">
            <span class="text">{{item.name}}</span>
          </a>
        </template>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import CurrencyList from "~/components/utils/CurrencyList";
import AccordionItem from "~/components/utils/AccordionItem";
import Dropdown from "~/components/utils/Dropdown";
import CurrencyMixin from "~/mixins/CurrencyMixin";

export default {
  name: "MobileMenu",
  mixins: [CurrencyMixin],
  components: {
    CurrencyList,
    AccordionItem,
    Dropdown
  },
  props: {
    menu: Array,
    cities: Array,
    franceCities: Array,
    otherDestination: Array,
    offerLink: String,
    displayMyBooking: Boolean,
    close: Function
  },
  methods: {
    clickMegaMenu (name, link) {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': name,
          'clickURL': 'www.walksofitaly.com' + link.substring(0, link.length - 1)
        }
      })
    },
    clickHome () {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'Home',
          'clickURL': '/'
        }
      })
    },
    clickMegaMenuTW (name, link) {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': name,
          'clickURL': link.substring(0, link.length - 1).replace('https://', '')
        }
      })
    },
    getThumbnail (img) {
      return (img) ? '' + img : 'https://via.placeholder.com/60x60'
    }
  }
};
</script>
<style lang="scss" scoped>
.mobile-menu-hamburger {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 998;
  .menu-wrapper {
    background-color: $white;
    width: 326px;
    height: 100%;
    float: right;
    padding-left: 12px;
    .menu-content-wrapper {
      height: calc(100vh - 82px);
      overflow-y: scroll;
    }
  }
  .menu-header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 80px;
    .walks-logo {
      width: 106px;
      height: 34px;
    }
    border-bottom: 2px solid #F7F7F7;
  }
  .cities-menu {
    // margin-left: 32px;
    margin-right: 24px;
    padding: 8px 0;
    .sub-container {
      padding-top:10px;
      margin-left: 12px;
      .sub-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .sub-item{
          color: $woi-red-main;
          @extend %paragraph-1;
          
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .city-name {
            flex: 1;
            margin-left: 12px;
          }
          .city-img {
            border-radius: 50%;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .underscore {
      // text-transform: none;
      color: $woi-gold-main;
      @extend %description-2;
      text-decoration: underline !important;
    }
  }
  
  span.currency-select-value {
    color: $woi-red-main;
    display: block;
    padding: 8px 10px;
  }
  a.menu-item {
    color: $woi-red-main;
    display: block;
    padding: 8px 0;
    span.text {
      @extend %subtitle-2-bold;
      // @extend %title-1-bold;
      text-transform: capitalize;
    }
    &:last-child {
      margin-bottom: 100px;
    }
  }

  .icon-close {
    color: $woi-red-main;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 28px;
    z-index: 999;
    cursor: pointer;
  }
  .label .thumbnail-icon.icon{
    margin-top:7px;
  }
}
</style>

