<template>
  <div class="mobile-menu-btn">
    <div>
      <img src="~/assets/svg/hamburger-icon-white.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: "HamburgerMenuButton"
};
</script>

