<template>
  <div @click="scrollTop">
    <div class="topnav-logo mobile-logo">
      <nuxt-link to="/" @click.native="clickHome()">
        <img class="lazyload" src="~/assets/svg/logo.svg" alt="Walks Of Italy logo">
      </nuxt-link>
    </div>
    <div class="topnav-logo desktop-logo hide-tablet">
      <nuxt-link to="/" @click.native="clickHome()">
        <img class="lazyload" src="~/assets/svg/logo.svg" alt="Walks Of Italy logo">
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Logo",
  methods: {
    scrollTop() {
      $("html, body").animate(
        {
          scrollTop: 0
        },
        400
      );
    },
    clickHome () {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'Home',
          'clickURL': '/'
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
$topnav-logo-width: 213px;
.topnav-logo {
  height: auto;
  display: flex;
  justify-content: center;
  background-color: transparent;
  &.desktop-logo {
    width: $topnav-logo-width;
  }
  &.mobile-logo {
    display: none;
  }
  @include max-width(1440px) {
    width: $topnav-logo-width;
  }
  @include max-width($tablet) {
    width: 106px;
    text-align: center;
  }
  @include max-width($desktop-sm) {
    &.desktop-logo {
      display: none;
    }
    &.mobile-logo {
      display: block;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

