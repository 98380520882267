<template>
  <div class="left-section">
    <img :src="walksLogo" loading="lazy" width="163px" height="123px" alt="walks logo">
    <div class="social">
      <SocialLink
        v-for="item in socialLinks"
        :key="item.name"
        :name="item.name"
        :link="item.link"
      />
    </div>
    <img
      class="secure lazyload"
      width="83px"
      height="84px"
      :data-src="secuteImg"
      alt="secure logo"
      loading="lazy"
    >
  </div>
</template>

<script>
import SocialLink from './SocialLink'
import secuteImg from '@/assets/img/footer/secure.png'
import walksLogo from '~/assets/img/footer/walksLogo.png'

export default {
  name: 'LeftSection',
  components: {
    SocialLink
  },
  data () {
    return {
      secuteImg: secuteImg,
      walksLogo: walksLogo,
      socialLinks: [
        {
          name: 'instagram',
          link: 'https://www.instagram.com/walkstours'
        },
        // TODO youtube
        {
          name: 'facebook',
          link: 'https://www.facebook.com/walkstours'
        },
        {
          name: 'twitter',
          link: 'https://twitter.com/walks'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.left-section{
  margin-top: 26px;
  // height: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .social {
    // width: 60%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    a {
      margin: 0 0;
    }
    .icon  {
      &.icon-twitter {
        position: relative;
      }
      &:hover {
        color: $woi-red-main;
      }
    }
    a:not(:first-child):not(:last-child){
      .icon{
        padding:2px 20px;
      }
    }
  }
  .secure{
    width: 84px;
    height: 84px;
  }
}

@include max-width($tablet) {
  .left-section{
    width: 60%;
    // height: 168px;
    margin-top: 62px;
    .social {
      width: 163px;
      margin-top: 16px;
      justify-content: space-between;
    }
    .secure{
      margin-top: 16px;
      width: 46px;
      height: 46px;
    }
  }
}
</style>
