<template>
  <div v-if="!isConfirmationPage()" class="currency-select-item">
    <span class="text currency-select-value">
      {{ getCurrencyLabel() }}
      <slot></slot>
    </span>
    <div :class="'topnav-dropdown ' + uiclass ">
      <a 
        v-for="currency in currencies"
        v-bind:key="currency.name"
        v-on:click="changeCurrency(currency.name)"
        :class="{'selected' : currency.name == currentCurrency()}">
        {{ currency.symbol + " " + currency.name }}
      </a>
    </div>
  </div>
</template>

<script>

import CurrencyMixin from '@/mixins/CurrencyMixin'

export default {
  name: 'CurrencyList',
  props: {
    uiclass: {
      type: String,
      default: 'red'
    }
  },
  mixins: [ CurrencyMixin ]
}
</script>
<style lang="scss" scoped>
.currency-select-item .topnav-dropdown{
  // width: 100%;
  min-width: 90px;
}

.currency-select-item {
  text-align: center;
  margin-bottom: -20px;
  padding-bottom: 20px;
  @extend %subtitle-2-bold;
  a{
    padding: 13px 15px!important;
    font-weight: 400;
    font-size: 18px!important;
    letter-spacing: 0;
    min-width: 50px;
  }

  > span.text {
    @extend %subtitle-2-bold;
    color: $woi-red-main;
  }

  .topnav-dropdown.red {
    z-index: 1000;
    border-top: 1px solid $woi-red-main;
    border-left: 1px solid $woi-red-main;
    border-right: 1px solid $woi-red-main;
    a{
      color: $woi-red-main!important;
      border-bottom:1px solid $woi-red-main;
    }
    a:hover, a.selected{
      background: $woi-red-main;
      color: $white!important;
    }
  }

  .topnav-dropdown.gold{
    a{
      color: $white!important;
      border-top:1px solid $white;
    }
    background: $woi-gold-dark;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    a:hover{
      background: $white;
      color: $woi-gold-dark!important;
    }
  }
}
</style>

