<template>
  <a class="social-media-link"
    target="_blank"
    :href="link"
    @click="gtmPushLinkClick({
      event: 'clickFooterMenu',
      text: name,
      url: link
    })"
  >
    <div :class="getClass()">
      <svgicon :name="name" width="22" height="22" />
    </div>
  </a>
</template>

<script>
export default {
  name: 'SocialLink',
  props: {
    link: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    getClass () {
      return 'icon icon-' + this.name
    }
  }
}
</script>
<style lang="scss" scoped>
a.social-media-link {
  color: $white;
  &:hover {
    color: $woi-gold-main;
  }
}
</style>