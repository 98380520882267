<template>
  <ul :style="{ marginBottom: mobile ? (fold ? '16px' : '32px') : '0px' }">
    <li class="title" :class="fold ? 'no-border' : ''" @click="switchFold">
      <span>{{ title }}</span>
      <svgicon class="arrow-icon" :class="{ down: !fold }" name="arrow-round" />
    </li>
    <li v-for="link in links" v-show="!fold" :key="link.name">
      <span v-if="link.name === 'Cookies Settings'">
        <!-- OneTrust Cookies Settings button start -->
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">
          Cookie Settings
        </button>
        <!-- OneTrust Cookies Settings button end -->
      </span>
      <span v-else>
        <a
          v-if="link.router.startsWith('http')"
          :href="link.router"
          :target="link.target"
        >
          {{ link.name }}
        </a>
        <nuxt-link
          v-else
          :to="link.router"
          @click.native="
            gtmPushLinkClick({
              event: 'clickFooterMenu',
              text: link.name,
              url: link.router
            })
          "
        >
          {{ link.name }}
        </nuxt-link>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FooterLinkColumn',
  props: {
    links: Array,
    title: String,
    mobile: Boolean
  },
  data () {
    return {
      fold: false
    }
  },
  watch: {
    mobile (to, from) {
      this.fold = to
    }
  },
  mounted () {
    this.fold = this.mobile
  },
  methods: {
    switchFold () {
      if (this.mobile) {
        this.fold = !this.fold
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button#ot-sdk-btn.ot-sdk-show-settings {
  @include title-2;
  color: $white;
  letter-spacing: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  &:hover {
    background-color: transparent;
    color: $woi-gold-main;
  }
  &:focus {
    outline: none;
  }
}
.title {
  @include title-2-bold;
  // text-transform: capitalize;
  color: $white;
  padding-bottom: 13px;
  border-bottom: 2px solid #f7f7f7;

  .arrow-icon {
    display: none;
    &.down {
      transform: rotate(0.25turn);
    }
    transition: transform 0.3s;
  }
}
ul {
  min-width: 20%;
  margin: 0;
  margin-top: 26px;
  padding: 0;
  list-style-type: none;
  margin-right: 27px;
  &:last-child {
    margin-right: 0;
    margin-left: 27px;
  }

  li {
    margin-bottom: 8px;
    a {
      @include title-2;
      color: $white;
    }
    &:hover {
      a {
        color: $woi-gold-main;
      }
    }
  }
}

@include max-width($tablet) {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 13px;
    padding-top: 13px;
    border-top: 2px solid #f7f7f7;
    border-bottom: 2px solid #f7f7f7;

    .arrow-icon {
      display: block;
    }

    &.no-border {
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 0;
      border-top: none;
      border-bottom: none;
    }
  }
  ul {
    margin-top: 0;
    width: 100%;

    &:last-child {
      margin-left: 0;
    }
  }
}
</style>
