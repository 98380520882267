// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".promo-signup .terms p a{color:#a08f56}.promo-signup .terms p a:hover{color:#5e2013}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandRed": "#5e2013",
	"brandGold": "#a08f56",
	"tableWidth": "1024px",
	"mobileExtra": "700px",
	"mobile": "640px",
	"mobileLarge": "480px",
	"blogCardWidthMobile": "200px",
	"locationCardWidth": "412px",
	"locationCardWidthTab": "300px",
	"locationCardWidthMobile": "242px"
};
module.exports = ___CSS_LOADER_EXPORT___;
