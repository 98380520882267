<template>
  <a :href="getRouteURL()" class="place">
    <div class="place-img-wrapper">
      <img v-if="this.image"
        :data-src="this.getContentfulImgURL({ url: this.image, width: 400, height: 300 })"
        :data-srcset="this.getImageSrcSetURL({ url: this.image, scale: 4 })"
        class="lazyload place-img"
      >
      <div class="dark-overlay"></div>
    </div>
    <div class="place-caption">
      <h3 class="header">{{ name }}</h3>
    </div>
  </a>
</template>
<script>
import LazyBackground from '@/components/utils/LazyBackground'
import imageOptimo from '~/mixins/imageOptimoMixin.js'
import { getTrailingSlash } from '@/helpers/route'
export default {
  name: 'DestinationBox',
  mixins: [imageOptimo],
  components: {
    LazyBackground
  },
  props: {
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  },
  computed: {
    secureImageUrl: function () {
      if (!this.image) {
        return null
      }

      let url = this.image
      url = url.replace(/^https?:\/\//i, '')
      url = 'https://' + url
      if (this.CheckMobile()) {
        return this.setFixedWidth(url, 300)
      } else {
        return this.setFixedWidth(url, 500)
      }
    },
    getImage () {
      if (this.secureImageUrl) {

      } else {
        return null;
      }
    },
    setBackground () {
      if (this.secureImageUrl) {
        return 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.1)), url(' + this.secureImageUrl + ')'
      } else {
        return 'white'
      }
    }
  },
  methods: {
    getRouteURL: function () {
      let url = this.url.toLowerCase() + getTrailingSlash(this.url)
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
// home page, city destination flex position
.place {
  width: 100%;
  aspect-ratio: 8/5;
  height: auto;
  max-width: 370px;
  max-height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    .place-img {
      transform: scale(1.07) rotate(-.33deg);
    }
  }
  cursor: pointer;
  overflow: hidden;
  .place-img-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgba(211, 211, 211, 0.5);
    // opacity: 0.5;
  }
  .place-img {
    height: 100%;
    width: 100%;
    transition: transform 940ms $cb-switch;
    object-fit: cover;
  }
  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    // opacity: 0.2;
  }
  .place-caption {
    position: absolute;
    height: fit-content;
    width: fit-content;
    max-width: 80%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .header {
    @include header-3;
    text-align: center;
    color: #fff;
    margin: 0;
  }
}
.place:last-child {
  .header {
    // color: $woi-gold-main;
    text-decoration-line: underline;
    text-transform: capitalize;
  }
  // border: 1px solid $blackberry;
  .place-img-wrapper {
    background-color: transparent;
    .dark-overlay {
      background-color: transparent;
    }
  }
  
}
@include max-width($tablet) {
  .place .header {
    @include title-1-bold;
    font-weight: 700 !important;
    font-size: 24px !important;
  }
}
@include max-width($mobile) {
  .place:last-child {
    .header {
      font-size: 20px !important;
      line-height: 24px;
    }
  }
}
@include max-width($mobile-l) {
  .place {
    max-width: 163px;
    // max-height: 103px;
  }
  .place:last-child {
    .header {
      @include title-1-bold;
      // font-weight: 700 !important;
      font-size: 24px !important;
    }
    max-width: 100%;
    width: 100%;
    grid-column: 1/3;
    height: 58px;
    aspect-ratio: unset;
  }
}
</style>

