<template>
  <main class="static-page page-404 no-margin">
      <div class="hero">
        <div>
          <h1>Page Not Found</h1>
          <p>Sorry, this page isn't available.</p>
        </div>
      </div>
      <div class="container">
        <h1 class="red">
          The link you clicked may be broken or the page may have been removed.
        </h1>
        <p>
          You can view our tours by visiting our city pages below. If you need further assistance, please <router-link to="/contact">contact us.</router-link>
        </p>
      </div>

  </main>
</template>

<script>

import Banner from '~/components/utils/Banner'
import Hero from '~/components/containers/home/Hero'
import LazyBackground from '~/components/utils/LazyBackground'
import PopularDest from '~/components//containers/home/Destinations'

export default {
  name: 'Page404',
  components: {
    Banner,
    Hero,
    PopularDest,
    LazyBackground
  },
  async asyncData ({ res }) {
    res.statusCode = 404
  },
  data () {
    return {
      cities: [
        {
          name: 'Rome',
          image: '//images.ctfassets.net/206xpozje0ny/79VlHREXC0oAK80Si2cGCY/bf8c1b81c7e506949fc664c198bdc5cc/RomeFeaturedIMG.jpg'
        },
        {
          name: 'Vatican',
          image: '//images.ctfassets.net/206xpozje0ny/1ipxgMS4tkOukY0AO2gsg8/4e48b71221243b628c7d3be962dbf3a6/VaticanFeaturedIMG.jpg'
        },
        {
          name: 'Florence',
          image: '//images.ctfassets.net/206xpozje0ny/Dfs8OWNCbAgGmOiE6EmUU/1304765079f043812d5a3133113ba353/FlorenceFeaturedIMG.jpg'
        },
        {
          name: 'Venice',
          image: '//images.ctfassets.net/206xpozje0ny/6uAit1LyA8WC6gcCAgUmYk/8fbc13e2a4804c10041c04188ee155d0/VeniceFeaturedIMG.jpg'
        }
      ]
    }
  },
  mounted () {
    this.gtmPageView({
        'hostname': 'www.walksofitaly.com',
        'fullURL': 'https://www.walksofitaly.com' + this.$route.path.substring(0, this.$route.path.length - 1),
        'pagePath': this.$route.path.substring(0, this.$route.path.length - 1),
        'pageTitle': '404 page not found',
        'referrer': document.referrer
      })
    window.dataLayer.push({
      'event': 'domReady',
      'domReady': {
        'hostname': 'www.walksofitaly.com',
        'fullURL': 'https://www.walksofitaly.com' + this.$route.path.substring(0, this.$route.path.length - 1),
        'pagePath': this.$route.path.substring(0, this.$route.path.length - 1),
        'pageTitle': '404 page not found'
      }
    })
  },
  metaInfo () {
    return {
      title: '404',
      meta: [
        {
          'property': 'og:title',
          'content': '404 page not found'
        },
        {
          'property': 'og:description',
          'content': '404 page not found'
        },
        {
          'property': 'og:type',
          'content': 'article'
        },
        {
          'property': 'og:url',
          'content': this.getMetaURL()
        },
        {
          'property': 'fb:app_id',
          'content': 1989124628036695
        },
        {
          vmid: 'description',
          name: 'description',
          content: '404 page not found'
        }
      ],
      link: [
        { 'rel': 'canonical',
          'href': this.getMetaURL()
        }
      ]
    }
  },
  methods: {
    /**
     * add '/' to route.path if not there
     * used in components's metaInfo method
     */
    getMetaURL () {
      let trailingSlash = ''
      if (!this.$route.path.match(/\/$/)) {
        trailingSlash = '/'
      }
      return 'https://www.walksofitaly.com' + this.$route.path + trailingSlash
    }
  }
}
</script>

