<template>
  <div class="sorter-dropdown dropdown">
    <div class="text">SORT BY:</div>
    <div class="dropdown-component" @click.prevent.stop="toggle(!status)" :class="{ open: status }">
      <span class="selected">{{ dataselected.label }} 
        <slot name="icon"></slot>
      </span> 
      <div class="dropdown-drawer">
        <div class="inner">
          <div class="inner-list">
            <span 
              @click="selectOption(option)"
              class="list-item" 
              v-for="(option, index) in options" 
              :key="index" 
              :class="{ 'selected' : option.value === selected.value }"> 
                {{ option.label }} 
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import promoMixin from  '~/mixins/promoMixin.js'

export default {
  mixins: [promoMixin],
  data () {
    return {
      dataselected: this.defaultValue || this.options[0],
      status: false
    }
  },
  watch: {
    defaultValue (val) {
      this.dataselected = val
    }
  },
  computed: {
    selected: {
      get () {
        return this.dataselected
      },
      set (value) {
        this.dataselected = value
      }
    }
  },
  mounted () {
    document.body.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    /**
     * used to detect when user click outside the dropdown,
     * if already open, then close the it
     */
    handleClickOutside () {
      this.status && this.toggle(!this.status)
    },
    selectOption (el) {
      this.dataselected = el
      this.callback && this.callback(el)
      this.$nextTick(() => {
        let impressionsTours = []
        this.tourList.forEach((tour, i) => {
          impressionsTours.push({
            'name': tour.titleShort,
            'id': tour.bookingTypeId,
            'price': this.getAnchorPriceNum(tour),
            'category': tour.citySlug.replace('-tours', '').charAt(0).toUpperCase() + tour.citySlug.replace('-tours', '').slice(1),
            'list': 'Listing Pages - ' + this.dataselected.label,
            'position': i + 1
          })
        })
        let dataGroup = {}
        this.chunkArray(impressionsTours, 3).forEach((group, i) => {
          dataGroup['group' + (i + 1)] = group
        })
        Vue.localStorage.set('filters', this.dataselected.label)
        window.dataLayer.push({
          'event': 'sortOptionClick',
          'click': {
            'clickText': this.dataselected.label
          },
          'ecommerce': {
            'currencyCode': 'USD',
            'impressions': dataGroup
          }
        })
      })
    },
    toggle (toggle) {
      this.status = toggle
    },
    chunkArray (myArray, chunkSize) {
      var index = 0
      var arrayLength = myArray.length
      var tempArray = []
      for (index = 0; index < arrayLength; index += chunkSize) {
        var myChunk = myArray.slice(index, index + chunkSize)
        tempArray.push(myChunk)
      }
      return tempArray
    }
  },
  /**
   * options Array[{Object}]
   * options.value : String
   * options.label : String
   */
  props: {
    options: {
      type: Array,
      required: true
    },
    tourList: Array,
    defaultValue: {
      type: [Boolean, Object],
      required: false,
      default: false
    },
    callback: Function
  }
}
</script>
<style lang="scss" scoped>
.sorter-dropdown.dropdown {
  display: flex;
  align-items: center;
  .text {
    color: #7D7879;
    @extend %subtitle-2-bold;
    margin-right: 8px;
  }
  .dropdown-component.open {
    opacity: 1;
    height: auto;
  }
  .dropdown-component .selected{
      border: 1px solid $woi-red-main;
      color: $woi-red-main;
      min-width: 200px;
  }


  .dropdown-drawer{
    .inner-list {
      opacity: 1;
    }
    .inner-list > * {
      color: $woi-red-main;
      // border: 1px solid $woi-grey!important;
      border-top: 0;
      border-left: 1px solid $woi-red-main;
      border-bottom: 1px solid $woi-red-main;
      border-right: 1px solid $woi-red-main;
      &:hover, &.active, &.selected{
        color: $white;
        background-color: $woi-red-main!important;
      }
    }
  }
}
</style>