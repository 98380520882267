<template>
  <div class="signup-modal-container" :class="{ nondisplay: !show }">
    <div>
      <div class="modal-title">
        {{ title }}
      </div>
      <div class="modal-text">
        {{ text }}
      </div>
      <div class="modal-btn" @click="close">
        Ok
      </div>
    </div>
    <div class="overlay" />
  </div>
</template>

<script>
export default {
  name: 'SignUpModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$base-z-index: 900;
.signup-modal-container {
  // center this div
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: $base-z-index + 110;
  font-family: 'Alegreya Sans';
  font-style: normal;

  /* Blackberry */
  color: #443c47;
  border-radius: 18px;
  padding: 0 8px;
  width: 536px;
  height: 371px;
  @include max-width($mobile) {
    width: 280px;
    height: 300px;
  }

  /* White Marble */
  background: #f7f7f7;

  /* Shadow */
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  border-radius: 8px;
  padding: 31px 22px;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-title {
      font-weight: 900;
      font-size: 40px;
      line-height: 52px;
      @include max-width($mobile) {
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
      }

      /* identical to box height, or 130% */
      text-transform: capitalize;
    }
    .modal-text {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 80px;
      @include max-width($mobile) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
    .modal-btn {
      cursor: pointer;
      background: $woi-gold-main;
      color: #ffffff;
      text-align: center;
      border-radius: 8px;
      padding: 16px 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #f7f7f7;
    }
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background: #ffffff;
    opacity: 0.5;
  }
}

.nondisplay {
  display: none;
}
</style>
