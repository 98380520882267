<template>
  <div class="topnav-cart" @click="openCart()" :class="{gold: isStickyHeader}">
    <svgicon id="header-cart-icon" class="icon" name="cart" width="26" height="26" />
    <span id="header-cart-items" class="item-count" v-if="this.anchorCarts > 0">
      <span>{{ this.anchorCarts }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RedirectMixin from "@/mixins/RedirectMixin";
export default {
  name: "HeaderCartButton",
  mixins: [RedirectMixin],
  props: {
    amount: Number,
    isStickyHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      anchorCarts: 'anchorCarts'
    })
  },
  methods: {
    openCart () {
      // get the current state of cart items before open
      this.$store.dispatch('updateCart')
      if (this.anchorCarts > 0) {
        this.$router.push('/payment')
        return
      }
      const cartElem = document.getElementById('cart-dd')
      const bg = document.getElementById('bg-nav')
      cartElem.classList.add('active')
      cartElem.style.display = 'block'
      if (bg.classList.contains('show') === true || bg.classList.contains('open') === true) {
        bg.classList.add('open')
      } else {
        bg.classList.toggle('open')
      }
      this.$store.commit('setHeaderCartVisible', true)
    },
  }
};
</script>

<style lang="scss" scoped>
.topnav-cart {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  #header-cart-icon {
    color: $woi-red-main;
  }
    
  #header-cart-items {
    position: absolute;
    right: -14px;
    top: -6px;
    background: $woi-gold-main;
    border-radius: 50%;
    color:#fff;
    font-weight: 400;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      font-size: 14px;
    }
  }
  &.gold {
    #header-cart-icon {
      color: $white;
    }
    #header-cart-items {
      background: $woi-red-dark;
    }
  }
}
</style>


