<template>
  <div class="thumbnail-menu" :class="{ small: variant === 'small' }">
    <div class="thumbnail-img">
      <img :data-src="getThumbnail(img)" class="city-img lazyload" width="100%" height="auto"/>
    </div>
    <div class="thumbnail-bottom">
      <div v-if="label" class="thumbnail-label">{{label}}</div>
      <div>
        <svgicon class="arrow-icon" name="arrow-round" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuThumbnail',
  props: {
    label: {
      type: String,
      required: false
    },
    img: {
      type: String,
      required: false
    },
    variant: {
      type: String,
      required: false
    }
  },
  methods: {
    getThumbnail (img) {
      return (img) ? '' + img : 'https://via.placeholder.com/60x60'
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-menu {
  color: $woi-red-main;
  display: flex;
  flex-direction: column;
  transition: all 400ms ease;

  .thumbnail-img > img {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    object-position: center;
  }
  .thumbnail-label {
    font-family: "Vollkorn" !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
  }
  .thumbnail-bottom {
    @extend %description-1-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    .arrow-icon {
      width: 9px;
      height: 18px;
    }
  }
  &:hover {
    opacity: 0.8;
  }

  &.small {
    .thumbnail-label {
      font-size: 12px;
      line-height: 20px;
    }
    .thumbnail-bottom {
      margin-top: 4px;
      align-items: flex-end;
      .arrow-icon {
        width: 6px;
        height: 12px;
      }
    }
  }
}

.capsOff {
  text-transform: none;
}
</style>

