<template>
    <div class="sticky-header"  v-if="!isPostpurchasePage() && !isConfirmationPage()">
      <!-- rome-tours/pristine-sistine-chapel-tour/ -->
        <div v-if="!isConfirmationPage() && !isTourDetailPage() && !isPostpurchasePage()"  class="container simple-sticky">
          <nuxt-link to="/" @click.native="clickHome()">
            <img @click="scrollTop" class="icon icon-collapse lazyload" data-src="/lys-flower-white.svg" />
          </nuxt-link>
          <div class="right-side">
            <div class="topnav-item">
              <a @click="scrollTopDataLayer">
                <span class="text">
                  view tours
                </span>
              </a>
            </div>
            <div class="topnav-item" v-for="(link, index) in menu" :key="index">
              <template v-if="link.name !== 'blog'">
                <nuxt-link :to="link.router" @click.native="clickMegaMenu(link.name, link.router)">
                  <span class='text'>{{link.name}}</span>
                </nuxt-link>
              </template>
              <template v-else>
                <a :href="link.router" target="_blank" @click="clickMegaMenu(link.name, '/blog/')">
                  <span class='text'>{{link.name}}</span>
                </a>
              </template>
              <!-- <span class='slash' v-if="index < menu.length - 1">/</span> -->
            </div>
          </div>
          <HeaderCartButton :isStickyHeader="true" />
        </div>
        <div class="tour-detail-sticky-header container" v-if="isTourDetailPage()" >
          <div class="tour-short-title"  @click="scrollTop">{{tour.title}}</div>
          <button class="btn-book-now-top btn-anim" v-on:click="backToTop()">Book Now</button>
        </div>
        <div class="tour-tabs-container">
          <div class="tabs city-tour-tabs container" v-if="isTourDetailPage()">
            <a class="tab-item city-tour-tab" href="#tag-gallery" >GALLERY</a>
            <a class="tab-item city-tour-tab city-tour-tab-first" href="#tag-key-details" >KEY DETAILS</a>
            <a class="tab-item city-tour-tab" href="#tag-highlights" >HIGHLIGHTS</a>
            <a class="tab-item city-tour-tab" href="#tag-description" >DESCRIPTION</a>
            <a class="tab-item city-tour-tab" href="#tag-site-visited" >SITES VISITED</a>
            <a class="tab-item city-tour-tab" href="#tag-reviews" >REVIEWS</a>
          </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import HeaderCartButton from '~/components/utils/HeaderCartButton'
import { mapGetters } from 'vuex'

export default {
  name: 'StickyHeader',
  components: {
    HeaderCartButton
  },
  computed: {
    ...mapGetters([
      'tour'
    ])
  },
  data () {
    return {
      menu: [
        { name: 'blog', router: 'https://www.walksofitaly.com/blog/' },
        { name: 'about', router: '/about/' },
        { name: 'faq', router: '/faq/' },
        { name: 'contact', router: '/contact/' }
      ]
    }
  },
  mounted () {
    $(window).scroll(function () {
      let top = $(window).scrollTop()
      let $tofix = $('.tour-detail-header')
      let w = $(window).width()
      if (w >= 1024) {
        if ($tofix.length) {
          if (top > $tofix.offset().top && !$tofix.hasClass('compact')) {
            $tofix.addClass('compact')
          }
          if (top < 140) {
            $tofix.removeClass('compact')
            // top < $tofix.offset().top
          }
        }
      }
    })
    $(window).scroll((e) => {
      var scrollPos = $(document).scrollTop()
      if (scrollPos > 180) {
        $('.sticky-header').addClass('visible')
        document.getElementById('cart-dd').style.top = '64px'
      } else {
        $('.sticky-header').removeClass('visible')
        document.getElementById('cart-dd').style.top = '78px'
      }
    })
  },
  updated () {
    if (process.browser) {
      this.scrollToItem()
    }
  },
  methods: {
    scrollTop () {
      $('html, body').animate({scrollTop: 0}, 400)
    },
    isConfirmationPage () {
      if (this.$route.path === '/confirm/') { // not sure what path you wana use
        return true
      } else {
        return false
      }
    },
    isTourDetailPage () {
      var city = this.$route.params.city
      var tour = this.$route.params.tour
      var path = this.$route.path
      if (city !== undefined && tour !== undefined && path.includes('compare') === false && path.includes('reviews') === false) {
        return true
      }
      return false
    },
    isPostpurchasePage () {
      return false
    },
    scrollToItem (e) {
      let $a = $('.city-tour-tabs .tab-item')
      $a.click(function (e) {
        e.preventDefault()
        let id = $(this).attr('href')
        $('html, body').animate({
          scrollTop: $(id).offset().top - 120
        }, 400)
      })
    },
    backToTop () {
      // back to booking widget
      $('html, body').animate({scrollTop: 800}, 400)
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'BOOK NOW',
          'clickURL': 'www.walksofitaly.com' + this.$route.path.substring(0, this.$route.path.length - 1)
        }
      })
    },
    clickMegaMenu (name, link) {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': name,
          'clickURL': 'www.walksofitaly.com' + link.substring(0, link.length - 1)
        }
      })
    },
    clickHome () {
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'Home',
          'clickURL': '/'
        }
      })
    },
    scrollTopDataLayer () {
      $('html, body').animate({scrollTop: 0}, 400)
      window.dataLayer.push({
        'event': 'clickMegaMenu',
        'click': {
          'clickText': 'View Tours',
          'clickURL': window?.location.host + this.$route.path.substring(0, this.$route.path.length - 1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// sticky header
@include max-width($desktop-sm){
  .sticky-header{
    display: none!important;
  }
}

// display: block;
.sticky-header{
  width:100%;
  background-color: $woi-red-main;
  color: $white;
  height: 65px;
  top: -200px;
  left: 0;
  right: 0;
  position: absolute;
  z-index:9999;
  opacity: 0.5;
  transition: all .3s ease 0s;
  border-bottom:2px solid $woi-red-dark;

  .simple-sticky{
    padding-top: 12px;
  }
  .topnav{
    background-color: transparent;
    a{
      color: $white;

    }
  }

  &.visible{
    top: 0;
    opacity: 1;
    position: fixed;

  }

  .container{
    display: flex;
    vertical-align: middle;
    align-items:center;
    a {
        color: $white;
        font-weight: 400;
    }
  }

  .right-side{
    margin-left: auto;
    letter-spacing: 1px;
    margin-right: 30px;
    display: flex;
    column-gap: 16px;

    .topnav-item{
      font-weight: 100;
      background-color: transparent;
      color: $white;
      float: left;
      .text {
        color: white;
      }
    }
  }
  .item-count{
    background: $woi-red-dark;
  }

  .tour-short-title {
    font-size: 20px!important;
    font-weight: 600!important;
    color: #fff!important;
    font-family: "Vollkorn"!important;
  }

}
</style>

