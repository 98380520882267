<template>
  <div class="bordered places-wrap-dest">
    <div class="container-center">
      <div v-if="featuredCities" class="places-wrap  places-wrap-pop-inner">
        <DestinationBox
          :class="{middle: index % 3 === 1}"
          v-for="(city, index) in features"
          :key="city.name"
          v-bind="city"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import DestinationBox from '@/components/utils/DestinationBox'

export default {
  name: 'Destinations',
  props: {
    propsFeatureCities: {
      type: Array,
      required: false
    }
  },
  components: {
    DestinationBox
  },
  computed: {
    ...mapGetters([
      'featuredCities'
    ]),
    features () {
      let list = (this.propsFeatureCities) ? this.propsFeatureCities : this.featuredCities
      list = list.slice(0, 8)
      list.push({
        url: "/paris-tours/",
        name: "Beyond Italy: PARIS",
        image: '/img/destinations/paris.jpg'
      })
      return list
    }
  },
  methods: {
    cityImageStyle: function (url) {
      return 'background: linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(' + url + '?w=1200) no-repeat 50% 50%/cover;'
    },
    getRouteURL: function (cityName) {
      return cityName.replace(' ', '-').toLowerCase() + '-tours'
    }
  }
}
</script>
<style lang="scss" scoped>
.places-wrap-dest {
  width: 100%;
  .places-wrap {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    justify-content: space-between;
    gap: 1.5rem;
  }
  .middle {
    justify-self: center;
  }
}

@include max-width($mobile-l) {
  .places-wrap-dest .places-wrap {
    grid-template: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .places-wrap-dest .middle {
    justify-self: normal;
  }
}
</style>
