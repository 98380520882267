<template>
  <LazyBackground
    class="heroContainer"
    :imageSource="heroImageObj().src"
    :loadingImage="heroImageObj().loading">
    <div class="hero"> 
      <div>
        <h1>{{this.heroTitle}}</h1>
        <p>{{this.heroDescription}}</p>
        <!-- <a href="https://www.takewalks.com/online-tours/" target="_blank">
          <button class="tfh-button">View Online Tours</button>
        </a> -->
      </div>
    </div>
  </LazyBackground>
</template>

<script>

import { mapGetters } from 'vuex'
import LazyBackground from '~/components/utils/LazyBackground'

import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  name: 'Hero',
  mixins: [imageOptimo],
  components: {
    LazyBackground
  },
  props: {
    heroImage: String
  },
  computed: {
    ...mapGetters([
      'heroTitle',
      'heroDescription'
    ])
  },
  methods: {
    heroImageObj () {
      return {
        src: 'https:' + this.setAutoWidth(this.heroImage),
        loading: 'https:' + this.setAutoWidth(this.heroImage)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tfh-button {
    background: #AD9C63;
    align-content: center;
    color: #FFF !important;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: .5em;
    padding: 0.5em 1em;
    text-transform: uppercase;
      &:hover {
        background: #887641;
      }
  }
</style>

