<template>
  <div
    v-if="canShow()"
    class="sales-banner"
    :style="bgStyle"
  >
    <div class="text-container">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="large-title" v-html="content.mainCallout" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="text" v-html="subTitle" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { includesPage } from '@/helpers/route'
import imageOptimo from '~/mixins/imageOptimoMixin.js'

export default {
  name: 'SalesBanner',
  mixins: [imageOptimo],
  computed: {
    ...mapGetters({
      screenWidth: 'screenWidth',
      content: 'ribbonBanner'
    }),
    bgStyle () {
      if (this.content.backgroundImage) {
        const imgUrl = this.getContentfulImgURL({ url: this.content.backgroundImage })
        return { backgroundImage: `url(${imgUrl})`, backgroundColor: this.content.backgroundColour }
      }
      return { backgroundColor: this.content.backgroundColour }
    },
    subTitle () {
      return this.content.subTitle
    }
  },
  methods: {
    canShow () {
      return includesPage(this.content?.pagesToShow, this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.sales-banner {
  > * {
    margin: 0;
  }
  position: relative;
  width: 100vw;
  // height: 92px;
  max-height: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
	background-size: cover;
  .text-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    color: #ffffff;
    text-align: center;

    .large-title {
      width: 100%;
      &::v-deep * {
        @extend %header-5;
        color: #ffffff;
        margin: 0;
      }
    }
    .text {
      width: 100%;
      margin-top: 8px;
      text-align: center;
      &::v-deep *{
        @extend %title-2;
        margin: 0;
      }
    }
  }
}
@include max-width($tablet) {
  .sales-banner {
    margin-top: 80px;
    margin-bottom: -80px;
    height: unset;
    max-height: unset;
  }
}
@include max-width($mobile) {
  .sales-banner {
    min-height: 64px;
    height: unset;
    max-height: unset;
    .text-container {
      .text {
        margin-top: 0;
      }
    }
  }
}
</style>
