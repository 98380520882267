<template>
  <div class="accordion-item-container">
    <div class="label" :class="accordionClass" @click="toggle($event)" :style="{color: textColor}">
      <span>{{ label }}</span>
      <img 
        :class="'lazyload thumbnail-icon icon ' + accordionClass" 
        width="18px" 
        height="18px" 
        :data-src="this.getIconColor(this.color)" />
    </div>
    <div ref="subMenu" :class="accordionClass + ' sub-menu'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  data () {
    return {
      isClosedComputed: this.isClosed,
      classValue: 'is-closed'
    }
  },
  computed: {
    accordionClass: function () {
      return (this.isClosedComputed) ? 'is-close' : 'is-open'
    }
  },
  // bind event handlers to the `handleResize`
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize (evt) {
      if (this.$refs.subMenu.className.indexOf('is-open') > -1) {
        this.$refs.subMenu.style.maxHeight = '9999px'
      }
    },
    toggle (evt) {
      this.isClosedComputed = !this.isClosedComputed
      let panel = evt.target.nextElementSibling
      if (panel.style.maxHeight || this.isClosedComputed) {
        panel.style.maxHeight = null
      } else {
        // panel.style.maxHeight = panel.scrollHeight + 'px'
      }
    },
    getIconColor (value) {
      return (value === 'white') ? require('~/assets/svg/arrow-icon-down.svg') : require('~/assets/svg/arrow-icon-down-red.svg')
    }
  },
  props: {
    label: {
      type: String
    },
    isClosed: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      required: false,
      default: 'white'
    },
    textColor: {
      type: String,
      required: false,
      default: 'white'
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion-item-container {
  div.label {
    width: 100%;
    color: $woi-red-main;
    @extend %subtitle-2-bold;
    // @extend %title-1-bold;
    transition: 0.3s ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      // float: right;
      transition: 0.3s ease-out;
      pointer-events: none;
    }
    img.is-closed{
      transform: rotate(180deg);
    }
    img.is-open{
      transform: rotate(-180deg);
    }
  }
  div.label + * {
    transition: 0.3s ease-out;
    max-height: 0;
    overflow: hidden;

    &.is-open{
      max-height: 9999px;

    }
  }
}
</style>

