<template>
  <div class="note">
    <svgicon name="logo_small_footer" width="25" height="25" class="icon icon-logo_small_footer"></svgicon>{{ 'Copyright © ' + new Date().getFullYear() + 'Walks LLC, 5323 Levander Loop, Austin, Texas, 78702-5146' }}
  </div>
</template>

<script>
export default {
  name: 'RightNote'
}
</script>

