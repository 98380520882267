<template>
  <div id="wrapper">
    <div id="app" canvas="container">
      <StickyHeader />
      <Header />
      <SalesBanner />
      <nuxt class="main-content" />
      <Footer />
      <CartDropdown />
      <PromoSignup />
      <div class="background-nav" id="bg-nav" />
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import StickyHeader from '~/components/utils/StickyHeader'
import NotificationBanner from '~/components/utils/NotificationBanner'
import Header from '~/components/containers/Header'
import Footer from '~/components/containers/Footer'
import CartDropdown from '~/components/containers/CartDropdown'
import SalesBanner from '@/components/utils/SalesBanner'
import PromoSignup from '~/components/utils/PromoSignup'
import * as svgicon from 'vue-svgicon'
import '~/assets/svgicon'

Vue.use(svgicon, {
  tagName: 'svgicon'
})
import { gtmDatalayer } from '@/mixins/gtmDatalayer.js'
Vue.mixin(gtmDatalayer)

export default {
  components: {
    StickyHeader,
    Header,
    CartDropdown,
    NotificationBanner,
    SalesBanner,
    PromoSignup,
    Footer
  },
  mounted () {
    const _this = this;
    if (process.browser) {
      $('html, body').animate({ scrollTop: 0 }, 400)
      this.checkForSession()
      geoip2.country(_this.geoIPSuccess, _this.geoIPError)
      // setup signup form popup
      this.$store.dispatch('getPopupSetting')
      // sales banner
      this.$store.dispatch('getSalesBanner')

      window.addEventListener('resize', () => {
        this.$store.commit('setScreenWidth', window?.innerWidth)
      })
      if (window?.innerWidth) {
        this.$store.commit('setScreenWidth', window.innerWidth)
      }
    }
  },
  methods: {
    checkForSession () {
      this.$store.dispatch('updateCart')
    },
    geoIPSuccess (geoipResponse) {
      let currency = 'USD'
      switch (geoipResponse.continent.code) {
        case 'NA':
        if (geoipResponse.country.iso_code === 'CA') {
            currency = 'CAD'
            Vue.localStorage.set('defaultCurrency', 'CAD')
          } else {
            currency = 'USD'
            Vue.localStorage.set('defaultCurrency', 'USD')
          }
          break
        case 'EU':
          if (geoipResponse.country.iso_code === 'GB') {
            currency = 'GBP'
            Vue.localStorage.set('defaultCurrency', 'GBP')
          } else {
            currency = 'EUR'
            Vue.localStorage.set('defaultCurrency', 'EUR')
          }
          break
        case 'OC':
          if (geoipResponse.country.iso_code === 'AU') {
            currency = 'AUD'
            Vue.localStorage.set('defaultCurrency', 'AUD')
          } else {
            currency = 'USD'
            Vue.localStorage.set('defaultCurrency', 'USD')

          }
          break
        default:
          currency = 'USD';
          this.$store.commit('setCurrency', 'USD')
          Vue.localStorage.set('defaultCurrency', 'USD')
      }
      if(!Vue.localStorage.get('Currency')){
        this.$store.commit('setCurrency', currency)
      }
      Vue.localStorage.set('userLocation', JSON.stringify({
        'country': geoipResponse.country.names.en,
        'continent': geoipResponse.continent.names.en
      }))
      const deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
      Vue.localStorage.set('deviceType', deviceType)
      window.dataLayer.push({
        device: deviceType,
        userLocationCountry: geoipResponse?.country?.names?.en,
        userLocationContinent: geoipResponse?.continent?.names?.en
      })
    },
    /**
      GeoIP2 error callback function
    */
    geoIPError () {
      Vue.localStorage.set('defaultCurrency', 'USD')
      return
    }
  }
}
</script>

<style>
@media only screen and (max-width: 1024px) {
  .main-content {
    /* 80px is header's height */
    margin-top: 80px;
  }
}

#wrapper.disable-scroll {
  overflow: hidden;
  max-height: 100vh;
}
  :root{
    scroll-behavior: unset !important;
  }
  /* Override styling for cookie consent notice */
  .cc_btn {
    height: 33px !important;
    border-radius: 0 !important;
    text-align: center !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    background-color: #541f03 !important;
    color: white !important;
    font-size: 18px;
  }
  .background-nav {
    height: 100vh;
    background: none;
    width: 100%;
    opacity: 0;
    position: initial;
    left: -9999px;
    display: none;
    -webkit-transition: opacity 300ms ease;
    -moz-transition: opacity 300ms ease;
    -ms-transition: opacity 300ms ease;
    -o-transition: opacity 300ms ease;
    transition: opacity 300ms ease;
  }
  .about-padding {
    padding-right: 16px !important;
  }
  .show {
    left: 0;
    opacity: 0.8;
    position: initial;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
  .open {
    left: 0;
    opacity: 0.8;
    position: initial;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 900;
  }
  @media only screen and (max-width: 1200px) {
    .background-nav {
      height: 100vh;
      background: #585858;
      width: 100%;
      opacity: 0;
      position: absolute;
      left: -9999px;
      display: block;
      -webkit-transition: opacity 300ms ease;
      -moz-transition: opacity 300ms ease;
      -ms-transition: opacity 300ms ease;
      -o-transition: opacity 300ms ease;
      transition: opacity 300ms ease;
    }
    .show {
      left: 0;
      opacity: 0.8;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
    .open {
      left: 0;
      opacity: 0.8;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 900;
    }
  }
</style>
