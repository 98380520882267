<template>
  <div id="wrapper">
    <div id="app" canvas="container">
      <nuxt />
    </div>
  </div>
</template>

<script>


export default {
  name: 'BlankLayout'
}
</script>
