<template>
  <div class="subscriber-container">
    <div class="wFormContainer">
      <div class="wFormHeader"></div>
      <div>
        <div class="wForm" id="113-WRPR" dir="ltr">
          <h3 class="wFormTitle" data-testid="form-title" id="113-T">
            Newsletter
          </h3>
          <div class="wFormSubTitle">
            {{ text }}
            <a href="/privacy">View our privacy policy</a>
          </div>
          <form
            method="post"
            action="https://hornblowergroup.tfaforms.net/api_v2/workflow/processor"
            class="hintsBelow labelsAbove"
            id="113"
            role="form"
          >
            <input type="hidden" id="tfa_5" name="tfa_5" value="" class="" />
            <div class="oneField field-container-D" id="tfa_3-D">
              <div class="inputWrapper">
                <input
                  aria-required="true"
                  type="text"
                  id="tfa_3"
                  name="tfa_3"
                  value=""
                  title="Email Address"
                  placeholder="Your email"
                  class="validate-email required"
                />
              </div>
            </div>
            <div class="actions" id="113-A">
              <input
                type="submit"
                data-label="Sign up"
                class="primaryAction"
                id="submit_button"
                value="Sign Up"
              />
            </div>
            <div style="clear: both"></div>
            <input
              type="hidden"
              value="113"
              name="tfa_dbFormId"
              id="tfa_dbFormId"
            /><input
              type="hidden"
              value=""
              name="tfa_dbResponseId"
              id="tfa_dbResponseId"
            /><input
              type="hidden"
              value="583a07d3b23af7230730454c3aa37e2f"
              name="tfa_dbControl"
              id="tfa_dbControl"
            /><input
              type="hidden"
              value=""
              name="tfa_dbWorkflowSessionUuid"
              id="tfa_dbWorkflowSessionUuid"
            /><input
              type="hidden"
              value="3"
              name="tfa_dbVersionId"
              id="tfa_dbVersionId"
            /><input
              type="hidden"
              value=""
              name="tfa_switchedoff"
              id="tfa_switchedoff"
            />
          </form>
        </div>
      </div>
      <div class="wFormFooter">
        <p class="supportInfo">
          <a
            target="new"
            class="contactInfoLink"
            href="https://hornblowergroup.tfaforms.net/forms/help/17"
            data-testid="contact-info-link"
            >Contact Information</a
          ><br />
        </p>
      </div>
      <p class="supportInfo"></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Subscriber",
  computed: {
    ...mapGetters([
      'screenWidth'
    ]),
    text () {
      if (this.screenWidth >= 768) {
        return 'Sign up to our newsletter, promotions and announcements.'
      } else {
        return 'Subscribe to our newsletter and promotions.'
      }
    }
  },
  mounted () {
    if (window) {
      // window.wFORMS?.behaviors && window.wFORMS.behaviors.prefill.skip = false
      const FORM_TIME_START = Math.floor(new Date().getTime() / 1000)
      let formElement = document.getElementById('tfa_0')
      if (formElement === null) {
        formElement = document.getElementById('0')
      }
      const appendJsTimerElement = function () {
        let formTimeDiff =
          Math.floor(new Date().getTime() / 1000) - FORM_TIME_START
        const cumulatedTimeElement = document.getElementById(
          'tfa_dbCumulatedTime'
        )
        if (cumulatedTimeElement !== null) {
          const cumulatedTime = parseInt(cumulatedTimeElement.value)
          if (cumulatedTime !== null && cumulatedTime > 0) {
            formTimeDiff += cumulatedTime
          }
        }
        const jsTimeInput = document.createElement('input')
        jsTimeInput.setAttribute('type', 'hidden')
        jsTimeInput.setAttribute('value', formTimeDiff.toString())
        jsTimeInput.setAttribute('name', 'tfa_dbElapsedJsTime')
        jsTimeInput.setAttribute('id', 'tfa_dbElapsedJsTime')
        jsTimeInput.setAttribute('autocomplete', 'off')
        if (formElement !== null) {
          formElement.appendChild(jsTimeInput)
        }
      }
      if (formElement !== null) {
        if (formElement.addEventListener) {
          formElement.addEventListener('submit', appendJsTimerElement, false)
        } else if (formElement.attachEvent) {
          formElement.attachEvent('onsubmit', appendJsTimerElement)
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.subscriber-container {
  background-color: $woi-gold-main;
  display: flex;
  align-items: center;
  flex-direction: column;
  .wFormHeader,
  .wFormFooter {
    display: none;
  }

  .wFormContainer,
  .wFormContainer .wForm {
    width: 100%;
    max-width: Min($content-width-ex, 100vw);
  }
}

.wFormContainer .wForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wFormTitle {
    text-align: left;
    color: #ffffff;
    @include header-5;
    font-family: "Alegreya Sans" !important;
    margin-bottom: 0;
  }
  .wFormSubTitle {
    color: white;
    margin-left: 12px;
    @include subtitle-2;
    font-family: "Alegreya Sans" !important;
    a {
      margin-left: 8px;
      color: white;
      text-decoration: underline !important;
      &:hover {
        color: $woi-gold-main;
      }
    }
  }
  form {
    max-width: 324px;
    min-width: 310px;
    display: flex;
    .oneField#tfa_3-D {
      min-width: 205px;
      max-width: 230px;
      // padding: 0;
      
    }
    .inputWrapper input#tfa_3 {
      transition: border-color 0.2s ease, background-color 0.2s ease;
      width: 100%;
      padding: 10px 5px;
      padding-top: 12px;
      padding-left: 12px;
      color: #6C757D;
      @include subtitle-2-bold;
      vertical-align: middle;
      line-height: 22px;
      &::placeholder {
        color: #6C757D;
        vertical-align: middle;
      }
    }
    .actions {
      max-width: 33%;
      margin-left: 12px;
      input#submit_button {
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        min-height: 45px;
        width: 100%;
        border: none;
        text-transform: uppercase;
        background: $blackberry;
        color: #fff;
        transition: background-color 0.2s ease;
      }
    }
  }
}


@include max-width($tablet) {
  .subscriber-container {
    .wFormContainer,
    .wFormContainer .wForm {
      width: 100%;
      max-width: 100%;
    }
  }
  .wFormContainer .wForm {
    // display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: unset;
    .wFormTitle {
      margin-top: 8px;
    }
    .wFormSubTitle {
      margin-top: 8px;
      margin-left: 0;
      a {
        margin-left: 2px;
      }
    }
    form {
      margin-top: 32px;
      max-width: 100%;
      min-width: 100%;
    }
  }
}

@include max-width($mobile) {
  .wFormContainer .wForm {
    form {
      .actions {
        input#submit_button {
          background: $woi-red-main;
        }
      }
    }
  }
}
</style>
